// Generated by Framer (575e68f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["EFFUZBBjx", "wxAlm4O2W"];

const serializationHash = "framer-lQ6qi"

const variantClassNames = {EFFUZBBjx: "framer-v-rqsv5r", wxAlm4O2W: "framer-v-i75f5d"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Active: "wxAlm4O2W", Deactive: "EFFUZBBjx"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, F3BUeO74v: click ?? props.F3BUeO74v, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "EFFUZBBjx"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, F3BUeO74v, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "EFFUZBBjx", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTappjlmty = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (F3BUeO74v) {const res = await F3BUeO74v(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-rqsv5r", className, classNames)} data-framer-name={"Deactive"} data-highlight layoutDependency={layoutDependency} layoutId={"EFFUZBBjx"} onTap={onTappjlmty} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({wxAlm4O2W: {"data-framer-name": "Active"}}, baseVariant, gestureVariant)}><motion.div className={"framer-fs4aq3"} layoutDependency={layoutDependency} layoutId={"HlqIHB80i"} style={{backgroundColor: "var(--token-f6eac330-98ef-414f-ae51-d5f8e67d146b, rgb(68, 68, 68))", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, rotate: 0}} variants={{wxAlm4O2W: {rotate: 45}}}/><motion.div className={"framer-1pa2cmq"} layoutDependency={layoutDependency} layoutId={"paCSBCYq5"} style={{backgroundColor: "var(--token-f6eac330-98ef-414f-ae51-d5f8e67d146b, rgb(68, 68, 68))", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, rotate: 0}} variants={{wxAlm4O2W: {rotate: -45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lQ6qi.framer-1erd0xk, .framer-lQ6qi .framer-1erd0xk { display: block; }", ".framer-lQ6qi.framer-rqsv5r { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-lQ6qi .framer-fs4aq3, .framer-lQ6qi .framer-1pa2cmq { flex: none; height: 4px; overflow: visible; position: relative; width: 35px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-lQ6qi.framer-rqsv5r { gap: 0px; } .framer-lQ6qi.framer-rqsv5r > * { margin: 0px; margin-bottom: calc(5px / 2); margin-top: calc(5px / 2); } .framer-lQ6qi.framer-rqsv5r > :first-child { margin-top: 0px; } .framer-lQ6qi.framer-rqsv5r > :last-child { margin-bottom: 0px; } }", ".framer-lQ6qi.framer-v-i75f5d .framer-fs4aq3 { z-index: 1; }", ".framer-lQ6qi.framer-v-i75f5d .framer-1pa2cmq { left: calc(50.00000000000002% - 35px / 2); position: absolute; top: calc(50.00000000000002% - 4px / 2); z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 13
 * @framerIntrinsicWidth 35
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"wxAlm4O2W":{"layout":["auto","auto"]}}}
 * @framerVariables {"F3BUeO74v":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerUp7z4OUhG: React.ComponentType<Props> = withCSS(Component, css, "framer-lQ6qi") as typeof Component;
export default FramerUp7z4OUhG;

FramerUp7z4OUhG.displayName = "Hamburger Menu";

FramerUp7z4OUhG.defaultProps = {height: 13, width: 35};

addPropertyControls(FramerUp7z4OUhG, {variant: {options: ["EFFUZBBjx", "wxAlm4O2W"], optionTitles: ["Deactive", "Active"], title: "Variant", type: ControlType.Enum}, F3BUeO74v: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerUp7z4OUhG, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})